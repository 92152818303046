import Grid from '@mui/material/Grid'
import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  ListItemAvatar,
  Slider,
  Stack,
  SvgIcon,
  TextField,
  ToggleButtonGroup,
  Typography,
  keyframes,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import MuiToggleButton from '@mui/material/ToggleButton'
import { createTopic } from '../api/openai'
import { err, isDefined, wait } from '../utils/functions'
import Component from '../components/common/Component'
import { styled } from '@mui/material/styles'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ClearIcon from '@mui/icons-material/Clear'
import InfoIcon from '@mui/icons-material/Info'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import PeopleIcon from '@mui/icons-material/People'
import GroupsIcon from '@mui/icons-material/Groups'
import PersonIcon from '@mui/icons-material/Person'
import CreateIcon from '@mui/icons-material/Create'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import BackgroundAnimation from '../components/common/BackgroundAnimation'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react'
import useSound from 'use-sound'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeDownIcon from '@mui/icons-material/VolumeDown'

const socketUrl = `ws://localhost:${process.env.SERVER_PORT || 3000}/ws`

const mainColor = '#00B3FF'

type Item = {
  title: string
  metric?: string
  index: number
  points: number
  team?: number
  editable?: boolean
  guessed?: boolean
}

type Player = {
  id?: string
  name: string
  team?: number
  icon?: string
}

const DEFAULT_ICONS = [
  '👽',
  '🤖',
  '🐵',
  '🐶',
  '🦊',
  '🐱',
  '🐴',
  '🦄',
  '🐮',
  '🐷',
  '🐭',
  '🐹',
  '🐰',
  '🐻',
  '🐨',
  '🐼',
  '🐔',
  '🐸',
  '🐲',
  '🐙',
]

const getTeamColor = (team: number | undefined): string | undefined => {
  if (!team) return undefined
  if (team === 1) return '#FFAC48'
  if (team === 2) return '#00B3FF'
}

const sortAlpha = (list: Item[]): Item[] => {
  return list.sort((a, b) => a.title.localeCompare(b.title))
}

const sortIndex = (list: Item[]): Item[] => {
  return list.sort((a, b) => a.index - b.index)
}

const parseToList = (result: string): Item[] => {
  const match = [
    ...result.matchAll(/([0-9]+)\s?\.\s?(.*?)\s?(\[.*?\])?(?:\n|$)/gim),
  ]

  const itemsLength = match.length
  const items = match.map((rex): Item | undefined => {
    if (!rex || !rex[2]) {
      return undefined
    }

    return {
      title: rex[2],
      metric: rex[3] ? rex[3].replaceAll('[', '').replaceAll(']', '') : '',
      index: rex[1] ? parseInt(rex[1]) : 0,
      points: rex[1] ? itemsLength - parseInt(rex[1]) + 1 : 0,
      team: undefined,
    }
  })

  return items.filter(isDefined)
}

const parseTopic = (result: string): string | undefined => {
  const match = [...result.matchAll(/Topic: (.*)\n/gim)]
  return match?.[0]?.[1]
}

export default function Dashboard(): JSX.Element {
  const theme = useTheme()
  const [topic, setTopic] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<Item[]>([])
  const [players, setPlayers] = useState<Player[]>([
    {
      name: 'default',
      team: 1,
      id: '1239710239190u31u90321031',
    },
  ])
  const [length, setLength] = useState<string>('10')
  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false)
  const [infoOpen, setInfoOpen] = useState<boolean>(false)
  const [showGuesses, setShowGuesses] = useState<boolean>(false)
  const [turn, setTurn] = useState<number>(1)
  const [guesses, setGuesses] = useState<number[]>([])

  const [gameState, setGameState] = useState<string>('pre')
  const [teamTurn, setTeamTurn] = useState<number>(1)
  const [teamWinner, setTeamWinner] = useState<number | undefined>(undefined)
  const [teamOneScore, setTeamOneScore] = useState<number>(0)
  const [teamTwoScore, setTeamTwoScore] = useState<number>(0)
  const [mode, setMode] = useState<string>('multi')
  const [multiMode, setMultiMode] = useState<string>('local')
  const [joinGameCode, setJoinGameCode] = useState<string>('')
  const [showEmojiPreview, setShowEmojiPreview] = useState<boolean>(false)
  const [selectedPlayer, setSelectedPlayer] = useState<Player | undefined>(
    undefined,
  )
  const [gameCode, setGameCode] = useState<string>('')
  const [volumeLevel, setVolumeLevel] = useState<number>(50)
  const [ws, setWs] = useState<WebSocket>()

  const [connectionId, setConnectionId] = useState<string>('')
  useEffect(() => {
    console.log(ws)

    const websocket = new WebSocket(socketUrl)
    websocket.addEventListener('open', (event) => {
      console.log('WebSocket is connected')
    })

    websocket.addEventListener('close', (event) => {
      console.log('WebSocket is closed.')
    })

    websocket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data)

      console.log(message)
      switch (message.event) {
        case 'connected': {
          setConnectionId(message.data)
          return
        }

        case 'close': {
          console.log('Connection CLOSED')
          return
        }

        case '__ping__': {
          console.log('Recieved ping', 'sending pong')
          websocket.send(JSON.stringify({ event: '__pong__' }))

          return
        }

        case 'createdLobby': {
          setGameCode(message.data.code)
          const newPlayers = message.data.players.slice()
          setPlayers(newPlayers)
          return
        }

        case 'joinedLobby': {
          setGameCode(message.data.code)
          setPlayers(message.data.players)
          return
        }
        case 'playerJoinedLobby': {
          setPlayers((players) => players.concat([message.data.player]))
          return
        }

        case 'leftLobby': {
          setGameCode(message.data.code)
          setPlayers(message.data.players)
          return
        }

        case 'closedLobby': {
          //
          // setGameCode(message.data.code)
          return
        }

        case 'startedGame': {
          startGame()
          return
        }

        case 'editedGame': {
          const prompt = message.data.prompt
          const list = message.data.list
          setList(list)
          setTopic(prompt)
          return
        }
      }
    })

    setWs(websocket)
  }, [])

  // const [guessTime, setGuessTime] = useState<string>('')

  // const [playing, toggle] = useAudio(`public/audio/space_jazz.mp3`)

  const [musicIsPlaying, setMusicIsPlaying] = useState<boolean>(false)
  const [playMusic, { stop: stopMusic }] = useSound(
    window.location.origin + '/audio/space_jazz.mp3',
    { volume: Math.min(volumeLevel / 100, 1) },
  )

  const [volumeExpanded, setVolumeExpanded] = useState<boolean>(false)

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  // const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
  //   useWebSocket(socketUrl, {
  //     onOpen: () => console.log('opened'),
  //     // Will attempt to reconnect on all close events, such as server shutting down
  //     shouldReconnect: (closeEvent) => true,
  //   })

  // useEffect(() => {
  //   getWebSocket()?.addEventListener('update', (data) => {
  //     console.log(data)
  //   })
  //   // console.log('Connection state changed')
  //   // if (readyState === ReadyState.OPEN) {
  //   //   sendJsonMessage({
  //   //     event: 'subscribe',
  //   //     data: {
  //   //       channel: 'general-chatroom',
  //   //     },
  //   //   })
  //   // }
  // }, [readyState, sendJsonMessage])

  // useEffect(() => {
  //   console.log(`Socket Message: ${JSON.stringify(lastJsonMessage)}`)
  // }, [lastJsonMessage])

  const handleGameOver = () => {
    const allSelected = list.every((item) => item.team !== undefined)

    if (allSelected) {
      setShowGuesses(true)
      setGameState('over')
    } else {
      return
    }
  }

  useEffect(() => {
    if (teamOneScore - teamTwoScore < 0) {
      setTeamWinner(2)
    } else if (teamOneScore - teamTwoScore > 0) {
      setTeamWinner(1)
    } else {
      setTeamWinner(0)
    }
  }, [gameState, teamOneScore, teamTwoScore])

  const handleRestartGame = () => {
    setTurn(1)
    setTeamTurn(1)
    setTeamWinner(0)
    setTeamOneScore(0)
    setTeamTwoScore(0)
    setGuesses([])
    setShowGuesses(false)

    // reset teams
    resetListTeams()
    wait(100)
      .then(() => {
        setGameState('pre')
      })
      .catch((e) => {
        //
      })
  }

  const handlePreScreen = () => {
    setGameState('pre')
    if (mode === 'host') {
      handleSetHostMode()
    }
    handleRestartGame()
  }

  const resetListTeams = () => {
    setList(list.map((item) => ({ ...item, team: undefined })))
  }

  const handleSetHostMode = () => {
    setMode('host')
    setList(sortIndex(list))
  }

  const handleSetMultiplayerMode = () => {
    setMode('multi')
    setList(sortAlpha(list))
  }
  const handleSetSinglePlayerMode = () => {
    setMode('single')
    setList(sortAlpha(list))
  }

  const handleMusicToggle = () => {
    if (!musicIsPlaying) {
      playMusic()
    } else {
      stopMusic()
    }
    setMusicIsPlaying(!musicIsPlaying)
  }

  const handleItemClick = async (index: number) => {
    // copy array
    const newList = list.slice()
    const newItem = list[index]

    if (!newItem) return
    if (newItem?.team || newItem.guessed) return
    if (newItem.team !== undefined) return

    const minAvailableIndex = Math.min(
      ...list
        .map((item) => (item.team === undefined ? item.index : undefined))
        .filter(isDefined),
    )

    // Handle missed guess in multi and single
    if (mode !== 'host' && minAvailableIndex < newItem.index) {
      // Set item as guessed
      newItem.guessed = true
      newList[index] = newItem
      setList(newList)
      setGuesses(guesses.concat(-teamTurn))

      if (mode === 'multi') {
        setTeamTurn((teamTurn % 2) + 1)
      } else {
        setTeamOneScore(teamOneScore + 1)
      }
      return
    }

    // On correct guess
    // Increment turn on correct guess
    // const currentTurn = turn
    // setTurn(currentTurn + 1)
    setGuesses(guesses.concat(index))

    // Increment multi score on correct guess
    const score = 1
    if (mode === 'multi') {
      if (teamTurn === 1) {
        setTeamOneScore(teamOneScore + score)
      } else if (teamTurn === 2) {
        setTeamTwoScore(teamTwoScore + score)
      }
    } else {
      // Always increment on single
      setTeamOneScore(teamOneScore + 1)
    }

    // Remove guessed from list
    // set team
    newItem.team = teamTurn
    newList[index] = newItem
    const resetGuessedList = newList.map((l) => ({ ...l, guessed: false }))
    // update array
    setList(resetGuessedList)

    await wait(100)
  }

  const handleCreateTopic = async () => {
    if (!topic) {
      await handleAutoPrompt()
      return
    }
    setLoading(true)
    const quantity = length ? parseInt(length) : undefined
    createTopic(topic, quantity)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          const list = parseToList(res.data)
          if (mode === 'host') {
            setList(sortIndex(list))
          }

          if (mode === 'multi' && multiMode === 'party') {
            ws?.send(
              JSON.stringify({
                event: 'editGame',
                data: {
                  code: gameCode,
                  list,
                  prompt: topic,
                },
              }),
            )
            return
          }
          setList(sortAlpha(list))
        }
      })
      .catch((e) => err(e))
  }

  const handleCloseEmojiPreview = () => {
    setShowEmojiPreview(false)
    setSelectedPlayer(undefined)
  }

  const changePlayerIcon = (playerId: string | undefined, icon: string) => {
    if (!playerId) return

    const playerIndex = players.findIndex((p) => p.id === playerId)
    if (playerIndex === -1) return

    const newPlayers = players.slice()
    const newPlayer = newPlayers[playerIndex]
    if (!newPlayer) return

    newPlayer.icon = icon
    newPlayers[playerIndex] = newPlayer

    setPlayers(newPlayers)
  }

  const changePlayerTeam = (playerId: string | undefined) => {
    if (!playerId) return

    const playerIndex = players.findIndex((p) => p.id === playerId)
    if (playerIndex === -1) return

    const newPlayers = players.slice()
    const newPlayer = newPlayers[playerIndex]
    if (!newPlayer) return

    newPlayer.team = newPlayer.team === 1 ? 2 : 1
    newPlayers[playerIndex] = newPlayer

    setPlayers(newPlayers)
  }

  const handleSelectLocalGame = () => {
    setGameCode('')
    setMultiMode('local')
  }
  const handleSelectOnlineGame = () => {
    setGameCode('')
    setMultiMode('online')
  }

  const handleCreateLobby = () => {
    setMultiMode('party')
    ws?.send(
      JSON.stringify({
        event: 'createLobby',
        data: {
          hostId: connectionId,
        },
      }),
    )
  }

  const handleJoinLobby = () => {
    if (!joinGameCode) return

    ws?.send(
      JSON.stringify({
        event: 'joinLobby',
        data: {
          code: joinGameCode,
          player: {
            name: 'Sarah',
            team: 1,
          },
        },
      }),
    )
  }

  const handleLeaveLobby = () => {
    setGameCode('')
    // sendJsonMessage({
    //   type: 'leaveLobby',
    //   payload: {
    //     lobbyId: joinGameCode,
    //   },
    // })
  }

  const handleStartGame = () => {
    if (mode === 'multi' && multiMode === 'party') {
      ws?.send(
        JSON.stringify({
          event: 'startGame',
          data: {
            code: gameCode,
          },
        }),
      )
      return
    }
    startGame()
  }

  const startGame = () => {
    if (mode === 'host') {
      setList((list) => sortIndex(list))
    } else {
      console.log(list)
      setList((list) => sortAlpha(list))
    }

    setGameState('active')
  }

  const handleMiss = () => {
    setGuesses(guesses.concat(-teamTurn))
    setTeamTurn((teamTurn % 2) + 1)
  }

  const handleOptionsExpand = () => {
    setOptionsExpanded(!optionsExpanded)
  }

  const handleAutoPrompt = async () => {
    setLoading(true)
    setTopic('')
    setList([])
    const quantity = length ? parseInt(length) : undefined
    createTopic(topic, quantity, 'auto')
      .then((res) => {
        setLoading(false)
        if (res.data && res.success) {
          const topic = parseTopic(res.data) || ''
          const list = parseToList(res.data)
          if (mode === 'multi' && multiMode === 'party') {
            ws?.send(
              JSON.stringify({
                event: 'editGame',
                data: {
                  code: gameCode,
                  prompt: topic,
                  list,
                },
              }),
            )
          }
          setTopic(topic)

          if (mode === 'host') {
            setList(sortIndex(list))
          }
          setList(sortAlpha(list))
        }
      })
      .catch((e) => err(e))
  }

  const handleAddItem = () => {
    setList(
      list
        .concat([
          {
            title: '',
            index: list.length + 1,
            points: 1,
            team: undefined,
            editable: true,
          },
        ])
        .map((item, i) => ({ ...item, points: list.length + 1 - i })),
    )
  }

  const handleItemChange = (index: number, value: string) => {
    const i = index - 1

    if (i < 0 || i > list.length - 1) return
    const newList = list.slice()
    const newItem = newList[i]

    if (!newItem) return
    newItem.title = value
    newList[i] = newItem
    setList(newList)
  }

  const handleDeleteItem = (index: number) => {
    const i = index - 1

    if (i < 0 || i > list.length - 1) return

    let newList = list.slice()
    newList.splice(i, 1)
    newList = newList.map((item, i) => ({ ...item, index: i + 1 }))
    setList(newList)
  }

  // useEffect(() => {
  //   renderCanvas()
  // }, [])

  // const renderCanvas = () => {
  //   const c = document.getElementById('canvas') as HTMLCanvasElement
  //   const ctx = c.getContext('2d')
  //   if (!ctx) return
  //   const img = document.getElementById('pencilImg') as HTMLImageElement
  //   if (!img) return

  //   for (let i = 0; i < 100; i++) {
  //     ctx.drawImage(img, 0, 0, 512, 512, 10 * i, 10 * i, 10, 10)
  //   }
  // }

  return (
    <div>
      <BackgroundAnimation />
      <img id="pencilImg" src="trophy.png" alt="" style={{ display: 'none' }} />
      <Container
        sx={{
          pb: 10,
          minHeight: '100vh',
        }}
      >
        <Box
          sx={{
            mt: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            paddingTop: '8px',
            paddingBottom: '32px',
            // padding: '16px',
            paddingX: '1rem',
            borderRadius: '8px',
            border: '2px solid #fff',
            // box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 30px 90px',
            // boxShadow:

            //   'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
          }}
        >
          <Grid container justifyContent="center" p={3} spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color="#222"
                sx={{
                  cursor: 'pointer',
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    textShadow:
                      '-2px 2px 1px rgba(0,0,0,0.27), 2px -2px 1px white',
                  }}
                  onClick={() => {
                    handlePreScreen()
                  }}
                >
                  Rank Trivia
                  <span>
                    <img
                      src="trophy.png"
                      width="60px"
                      height="60px"
                      alt="icon"
                      style={{
                        marginLeft: '10px',
                        position: 'relative',
                        display: 'inline',
                        top: '5px',
                      }}
                    />
                  </span>
                </p>

                <span
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 25,
                    opacity: 0.5,
                    zIndex: 10,
                  }}
                >
                  {musicIsPlaying ? (
                    <>
                      {fullScreen ? (
                        <>
                          <IconButton
                            size="large"
                            onClick={() => handleMusicToggle()}
                          >
                            <VolumeUpIcon fontSize="large" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <Grid
                            container
                            onMouseOver={() => setVolumeExpanded(true)}
                            onMouseLeave={() => setVolumeExpanded(false)}
                          >
                            <Grid item>
                              <Collapse
                                in={volumeExpanded}
                                orientation="horizontal"
                              >
                                <Box sx={{ width: 200, mt: 0.6 }}>
                                  <Stack direction="row" alignItems="center">
                                    <IconButton
                                      size="large"
                                      onClick={() => handleMusicToggle()}
                                    >
                                      <VolumeDownIcon fontSize="large" />
                                    </IconButton>
                                    <Slider
                                      aria-label="Volume"
                                      value={volumeLevel}
                                      onChange={(e, v) => {
                                        const value = v as number
                                        setVolumeLevel(Math.min(value, 100))
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Collapse>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="large"
                                onClick={() => handleMusicToggle()}
                              >
                                <VolumeUpIcon fontSize="large" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <IconButton
                      size="large"
                      onClick={() => handleMusicToggle()}
                    >
                      <VolumeOffIcon fontSize="large" />
                    </IconButton>
                  )}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <IconButton
                    size="small"
                    sx={{ fontSize: 15 }}
                    onClick={() => setInfoOpen(true)}
                  >
                    <InfoIcon fontSize="inherit" sx={{ color: '#aaa' }} />
                  </IconButton>
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{ color: 'gray', cursor: 'pointer' }}
                    onClick={() => setInfoOpen(true)}
                  >
                    How to Play
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {gameState === 'pre' ? (
            <>
              <Grid container justifyContent="center" spacing={1} pt={1}>
                {/* Mode */}
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item lg={4} md={4} xs={12}>
                      <ToggleButton
                        value="Single Player"
                        selected={mode === 'single'}
                        onClick={handleSetSinglePlayerMode}
                        size="large"
                        fullWidth
                        sx={{
                          backgroundColor: '#FFF',
                          border: '1px solid lightgray',
                          borderRadius: '10px',
                          height: '56px',
                          color: '#222',
                        }}
                      >
                        <Typography variant="h6">Single Player</Typography>
                        <SvgIcon color="disabled" sx={{ ml: 1 }}>
                          <PersonIcon fontSize="inherit" />
                        </SvgIcon>
                      </ToggleButton>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <ToggleButton
                        selected={mode === 'multi'}
                        onClick={handleSetMultiplayerMode}
                        value="Multiplayer"
                        size="large"
                        fullWidth
                        sx={{
                          backgroundColor: '#FFF',
                          border: '1px solid lightgray',
                          borderRadius: '10px',
                          height: '56px',
                          color: '#222',
                        }}
                      >
                        <Typography variant="h6">Multiplayer</Typography>
                        <SvgIcon color="disabled" sx={{ ml: 1 }}>
                          <PeopleIcon fontSize="inherit" />
                        </SvgIcon>
                      </ToggleButton>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <ToggleButton
                        value="Host"
                        selected={mode === 'host'}
                        onClick={handleSetHostMode}
                        size="large"
                        fullWidth
                        sx={{
                          backgroundColor: '#FFF',
                          border: '1px solid lightgray',
                          borderRadius: '10px',
                          height: '56px',
                          color: '#222',
                        }}
                      >
                        <Typography variant="h6">Host Mode</Typography>
                        <SvgIcon color="disabled" sx={{ ml: 1 }}>
                          <GroupsIcon fontSize="inherit" />
                        </SvgIcon>
                      </ToggleButton>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        p={4}
                        mt={4}
                        sx={{
                          backgroundColor: '#FFF',
                          border: '1px solid lightgray',
                          borderRadius: '10px',
                        }}
                      >
                        {mode === 'single' ? (
                          <Typography variant="body1" fontSize={18}>
                            Guess the highest ranking items in order. Score is
                            the number of guesses to complete the list.
                          </Typography>
                        ) : null}
                        {mode === 'multi' ? (
                          <Typography variant="body1" fontSize={18}>
                            Take turns guessing for the highest ranked items.
                            Correct guesses are awarded a point and another
                            turn.
                          </Typography>
                        ) : null}
                        {mode === 'host' ? (
                          <Typography variant="body1" fontSize={18}>
                            The Host is the only one who can view the list.
                            Teams take turns guessing for the highest ranked
                            items. Host can award guesses in any order.
                          </Typography>
                        ) : null}
                      </Box>
                    </Grid>

                    {mode === 'multi' ? (
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid item xs={12} md={12}>
                            <ToggleButtonGroup
                              fullWidth={true}
                              value={multiMode}
                              exclusive
                              sx={{ backgroundColor: 'white' }}
                            >
                              <ToggleButton
                                value="local"
                                size="small"
                                onClick={handleSelectLocalGame}
                                selected={multiMode === 'local'}
                              >
                                Local Play
                              </ToggleButton>
                              <ToggleButton
                                value="online"
                                size="small"
                                onClick={handleSelectOnlineGame}
                                selected={multiMode === 'online'}
                                disabled
                              >
                                Online
                              </ToggleButton>
                              <ToggleButton
                                value="party"
                                size="small"
                                onClick={handleCreateLobby}
                                selected={multiMode === 'party'}
                                disabled
                              >
                                Party
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                    {mode === 'multi' && gameCode && multiMode === 'party' ? (
                      <Grid item xs={12} md={6}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              onClick={handleJoinLobby}
                              component="span"
                              sx={{
                                position: 'relative',
                                float: 'right',
                                top: '4px',
                                right: '12px',
                                cursor: 'pointer',
                              }}
                              variant="body1"
                              color="gray"
                            >
                              Join Game
                            </Typography>
                            <Box
                              component="span"
                              sx={{
                                position: 'relative',
                                float: 'left',
                                top: '4px',
                                left: '12px',
                                cursor: 'pointer',
                              }}
                              color="gray"
                            >
                              <Grid container justifyContent="center">
                                <Grid item>
                                  <Typography variant="body1" component="span">
                                    Code:{' '}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    size="small"
                                    variant="standard"
                                    sx={{ width: '80px', ml: 1 }}
                                    inputProps={{
                                      style: {
                                        padding: 0.5,
                                        border: '',
                                      },
                                    }}
                                    value={joinGameCode}
                                    onChange={(e) =>
                                      setJoinGameCode(e.target.value)
                                    }
                                  ></TextField>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                backgroundColor: 'white',
                                border: '1px solid lightgray',
                                borderRadius: '10px',
                                paddingTop: '1em',
                                paddingBottom: '2em',
                                paddingLeft: '1em',
                                paddingRight: '1em',
                              }}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  marginBottom: '16px',
                                }}
                              >
                                <span
                                  style={{
                                    userSelect: 'none',
                                  }}
                                >
                                  <strong>Lobby</strong>
                                </span>
                                <GameCodeText>
                                  <Typography
                                    variant="h4"
                                    component="span"
                                    sx={{
                                      display: 'inline-block',
                                      padding: '2px 6px',
                                      borderRadius: '8px',
                                      border: '1px solid #999',
                                      color: 'black',
                                      cursor: 'pointer',
                                      backgroundColor: '#eee',
                                      marginLeft: '14px',
                                      rotate: '-2deg',
                                    }}
                                  >
                                    <code>
                                      <strong>{gameCode}</strong>
                                    </code>
                                  </Typography>
                                </GameCodeText>
                              </Typography>

                              <List>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  sx={{ px: '1em' }}
                                >
                                  <Grid item>
                                    <Typography variant="h6">Player</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6">Team</Typography>
                                  </Grid>
                                </Grid>
                                {players.length ? (
                                  players.map((player, i) => (
                                    <ListItem
                                      key={player.id}
                                      // secondaryAction={
                                      //   <IconButton
                                      //     edge="end"
                                      //     aria-label="delete"
                                      //     title="Delete"
                                      //     onClick={() => console.log('remove')}
                                      //   >
                                      //     <DeleteIcon />
                                      //   </IconButton>
                                      // }
                                    >
                                      <ListItemAvatar>
                                        <Avatar
                                          onClick={() => {
                                            setSelectedPlayer(player)
                                            setShowEmojiPreview(true)
                                          }}
                                          sx={{
                                            backgroundColor: '#eee',
                                          }}
                                        >
                                          {player.icon ||
                                            DEFAULT_ICONS[
                                              Math.floor(
                                                DEFAULT_ICONS.length *
                                                  Math.random(),
                                              )
                                            ]}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                        >
                                          <Grid item>
                                            <Typography component={'span'}>
                                              {player.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography
                                              color={getTeamColor(player.team)}
                                              sx={{ cursor: 'pointer' }}
                                              onClick={() =>
                                                changePlayerTeam(player.id)
                                              }
                                            >
                                              {player.team === 1
                                                ? 'Team 1'
                                                : 'Team 2'}
                                              <IconButton
                                                sx={{
                                                  padding: 0,
                                                  ml: 0.5,
                                                  fontSize: 18,
                                                }}
                                              >
                                                <AutorenewIcon
                                                  color="disabled"
                                                  fontSize="inherit"
                                                />
                                              </IconButton>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </ListItemText>
                                    </ListItem>
                                  ))
                                ) : (
                                  <Typography variant="body1" color="#888">
                                    Waiting For Players...
                                  </Typography>
                                )}
                              </List>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                {/* Topic */}
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={0} pt={8}>
                    {/* Topic Input */}
                    <Grid item xs={12}>
                      <Grid container alignContent="center" spacing={1}>
                        <div
                          className="flex-container"
                          style={{
                            display: 'flex',
                            justifyContent: 'stretch',
                            width: '100%',
                          }}
                        >
                          <div style={{ flexGrow: 11, margin: 1 }}>
                            <TextField
                              InputProps={{
                                sx: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              fullWidth={true}
                              placeholder="Search Topic"
                              onKeyDown={(key) => {
                                if (key.code === 'Enter') {
                                  const inputEl =
                                    document.activeElement as HTMLInputElement
                                  inputEl?.blur()
                                  void handleCreateTopic()
                                }
                              }}
                              value={topic}
                              onChange={(e) => setTopic(e.target.value)}
                            ></TextField>
                          </div>
                          <div style={{ flexGrow: 0, marginLeft: '0.5em' }}>
                            <Button
                              variant="contained"
                              onClick={handleCreateTopic}
                              disabled={loading}
                              size="large"
                              sx={{
                                backgroundColor: loading
                                  ? '#ddd !important'
                                  : mainColor,
                                borderRadius: '10px',
                                height: '56px',
                                p: 0,
                              }}
                            >
                              {loading === true ? (
                                <CircularProgress color="inherit" />
                              ) : (
                                <SvgIcon color="disabled">
                                  <CreateIcon fontSize="inherit" />
                                </SvgIcon>
                              )}
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    {/* Options */}
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">
                        <Grid
                          item
                          onClick={handleOptionsExpand}
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            component="div"
                            variant="body1"
                            color="gray"
                          >
                            Options
                            <ExpandMore expand={optionsExpanded}>
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </Typography>
                        </Grid>
                        <Grid item onClick={handleAutoPrompt}>
                          <Typography
                            component="div"
                            variant="body1"
                            color="gray"
                            mt={1}
                            sx={{ cursor: 'pointer' }}
                          >
                            Auto-Generate
                            <IconButton size="small" sx={{ fontSize: 15 }}>
                              <AutoAwesomeIcon fontSize="inherit" />
                            </IconButton>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Options Collapse */}
                    <Grid item xs={12}>
                      <Collapse in={optionsExpanded} sx={{ m: 2 }}>
                        <Box p={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                InputProps={{
                                  sx: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                  },
                                }}
                                fullWidth
                                type="number"
                                label="List Length"
                                value={`${length}`}
                                onChange={(e) =>
                                  setLength(e.target.value || '')
                                }
                                size="medium"
                              ></TextField>
                            </Grid>

                            {/* <Grid item xs={12}>
                            <TextField
                              InputProps={{
                                sx: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              fullWidth
                              type="number"
                              label="Guess Time Limit"
                              value={`${guessTime}`}
                              onChange={(e) =>
                                setGuessTime(e.target.value || '')
                              }
                              size="medium"
                            ></TextField>
                          </Grid> */}
                          </Grid>
                        </Box>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Grid>

                {/* List */}
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <List
                          sx={{
                            mt: 5,
                            backgroundColor: 'white',
                            border: '1px solid lightgray',
                            borderRadius: '10px',
                            paddingTop: '1.5em',
                            paddingBottom: '2em',
                            paddingLeft: '1em',
                            paddingRight: '1em',
                          }}
                        >
                          <Typography variant="h4">
                            <strong>List</strong>
                          </Typography>

                          {list.map((item, i) => (
                            <ListItem
                              key={item.title + i}
                              // secondaryAction={
                              //   <IconButton
                              //     edge="end"
                              //     aria-label="delete"
                              //     title="Delete"
                              //     onClick={() => console.log('remove')}
                              //   >
                              //     <DeleteIcon />
                              //   </IconButton>
                              // }
                            >
                              {mode === 'host' ? (
                                <>
                                  <ListItemText>
                                    <Grid container justifyContent="flex-start">
                                      <Grid item xs={1}>
                                        <Typography
                                          sx={{ mr: '3px' }}
                                          component="span"
                                        >
                                          {item.index}.{' '}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={10}>
                                        <EditableItem
                                          value={item.title}
                                          onChange={(value) =>
                                            handleItemChange(item.index, value)
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleDeleteItem(item.index)
                                          }
                                        >
                                          <ClearIcon
                                            fontSize="small"
                                            htmlColor="gray"
                                          ></ClearIcon>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </ListItemText>
                                </>
                              ) : (
                                <ListItemText
                                  primary={
                                    mode === 'host' ? (
                                      `${item.index}. ${item.title}${
                                        item.metric ? ` (${item.metric})` : ''
                                      }`
                                    ) : (
                                      <a
                                        href={`https://www.google.com/search?q=${item.title.replaceAll(
                                          ' ',
                                          '+',
                                        )}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.title}
                                      </a>
                                    )
                                  }
                                />
                              )}
                            </ListItem>
                          ))}
                          <Typography variant="body1" color="#888">
                            Search a Topic{' '}
                            {mode === 'host' ? (
                              <Typography
                                component="span"
                                variant="body1"
                                color="#888"
                              >
                                or{' '}
                                <Link
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleAddItem()
                                  }}
                                >
                                  Add Items
                                </Link>
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Typography>
                        </List>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        sx: {
                          backgroundColor: 'white',
                          borderRadius: '10px',
                        },
                      }}
                      label="List"
                      disabled={!moderatorMode}
                      multiline={true}
                      value={resultText}
                      onChange={(e) => handleSetResultText(e.target.value)}
                      rows={20}
                      fullWidth
                    ></TextField>
                  </Grid> */}

                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={handleStartGame}
                            disabled={!list.length || loading}
                            size="large"
                            sx={{
                              borderRadius: '10px',
                              height: '56px',
                              backgroundColor:
                                !list.length || loading
                                  ? '#ddd !important'
                                  : mainColor,
                            }}
                          >
                            <Typography>
                              <strong>Start Game</strong>
                            </Typography>
                            <SvgIcon color="disabled">
                              <PlayArrowIcon fontSize="inherit" />
                            </SvgIcon>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}

          {gameState !== 'pre' ? (
            <Grid container justifyContent="center" spacing={2} mb={2}>
              <Grid item xs={12}>
                <Component p={1}>
                  <Typography variant="h6" p={1}>
                    {topic}
                  </Typography>
                </Component>
              </Grid>
            </Grid>
          ) : null}

          {gameState !== 'pre' && mode !== 'single' ? (
            <>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      backgroundColor: '#FFF',
                      border: '1px solid lightgray',
                      borderRadius: '10px',
                    }}
                  >
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={6}>
                        <Grid container justifyContent="center">
                          <Grid item xs={12}>
                            <Typography variant="h6"> Team 1</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6">{teamOneScore}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container justifyContent="center">
                          <Grid item xs={12}>
                            <Typography variant="h6"> Team 2</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6">{teamTwoScore}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {gameState === 'over' ? (
                    <>
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                          {teamWinner !== undefined && teamWinner !== 0 ? (
                            <Component p={2} color={getTeamColor(teamWinner)}>
                              <Typography variant="h6">
                                Team {teamWinner} Wins!
                              </Typography>
                            </Component>
                          ) : null}
                          {teamWinner === 0 ? (
                            <Typography variant="h6">Tie!</Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid item xs={12}>
                            <Component p={2} color={getTeamColor(teamTurn)}>
                              <Typography variant="h6">
                                Team {teamTurn} Turn
                              </Typography>
                            </Component>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2}>
                    {list.map((item, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        key={`item-${item.title}`}
                        style={{ cursor: 'pointer' }}
                      >
                        <ItemGuessBox
                          item={item}
                          list={list}
                          mode={mode}
                          onClick={async () => {
                            await handleItemClick(i)
                            handleGameOver()
                          }}
                        />
                      </Grid>
                    ))}

                    {mode === 'host' ? (
                      <Grid item xs={12} sx={{ mt: 3 }}>
                        <Box onClick={handleMiss} style={{ cursor: 'pointer' }}>
                          <Component p={1} color="lightgray">
                            <Typography>Miss!</Typography>
                          </Component>
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <GuessesCollapse
                    show={showGuesses}
                    onChange={() => setShowGuesses(!showGuesses)}
                    list={list}
                    guesses={guesses}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          {gameState !== 'pre' && mode === 'single' ? (
            <>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography variant="h6">Score</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6">{teamOneScore}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {gameState === 'over' ? (
                    <>
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                          {teamWinner !== undefined && teamWinner !== 0 ? (
                            <Component p={1} color={getTeamColor(teamWinner)}>
                              <Typography variant="h6">
                                {teamOneScore < 20
                                  ? 'Nice score!'
                                  : 'Maybe next time...'}
                              </Typography>
                            </Component>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={1}>
                    {list.map((item, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        key={`item-${item.title}`}
                        style={{ cursor: 'pointer' }}
                      >
                        <ItemGuessBox
                          item={item}
                          list={list}
                          mode={mode}
                          onClick={async () => {
                            await handleItemClick(i)
                            handleGameOver()
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <GuessesCollapse
                    show={showGuesses}
                    onChange={() => setShowGuesses(!showGuesses)}
                    list={list}
                    guesses={guesses}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          {gameState === 'over' ? (
            <Grid container justifyContent="center" spacing={2} pt={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleRestartGame}
                  disabled={!list.length || loading}
                  size="large"
                  sx={{
                    backgroundColor:
                      !list.length || loading ? '#ddd !important' : mainColor,
                    borderRadius: '10px',
                    height: '56px',
                  }}
                >
                  <Typography>
                    <strong>New Game</strong>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={infoOpen}
        maxWidth="md"
        onClose={() => setInfoOpen(false)}
      >
        <DialogTitle>
          <Typography variant="h5">How to Play</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setInfoOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box p={4} sx={{ color: '#111' }}>
            <Typography variant="h5" mb={1}>
              Generate a List
            </Typography>
            <Typography>
              Search any category to generate a ranked list of items, or create
              a custom list in Host Mode.
            </Typography>
            <br />
            <br />
            <Typography>
              Categories can be anything you can image! Give your imagination a
              try!
            </Typography>
            <Typography variant="body2" color="#555">
              (We are working on features to save, share, and search across a
              library of lists. Keep tuned!)
            </Typography>
            <br />
            <br />
            <Typography variant="h5" mb={1}>
              Goal
            </Typography>
            <Typography>
              The goal of the game is to guess the highest ranked items in order
              from most to least.
            </Typography>
            <br />
            <br />
            <Typography variant="h5" mb={1}>
              Example
            </Typography>
            <Typography>Most popular coffee orders in the US:</Typography>
            <Grid container justifyContent="center">
              <Grid item>
                <List>
                  <ListItem sx={{ fontSize: '19px' }}>1. Black Coffee</ListItem>
                  <ListItem sx={{ fontSize: '19px' }}>2. Latte</ListItem>
                  <ListItem sx={{ fontSize: '19px' }}>3. Americano</ListItem>
                  <ListItem sx={{ fontSize: '19px' }}>4. Iced Coffee</ListItem>
                  <ListItem sx={{ fontSize: '19px' }}>5. Cold Brew</ListItem>
                  <ListItem sx={{ fontSize: '19px' }}>...</ListItem>
                </List>
              </Grid>
            </Grid>
            <br />
            <br />
            <Typography variant="h5" mb={1}>
              Single Player
            </Typography>
            <Typography>
              In SinglePlayer, try to guess the ranked items in order! Each
              guess tallies up a point, so the lower the score, the better.
            </Typography>
            <br />
            <br />
            <Typography variant="h5" mb={1}>
              Multiplayer
            </Typography>
            <Typography>
              In MultiPlayer, take turns guessing the ranked items in order!
              Each correct guess is awarded a point and another turn, so the
              team with the higher score wins
            </Typography>
            <br />
            <br />
            <Typography variant="h5" mb={1}>
              Host Mode
            </Typography>
            <Typography>
              In Host Mode, play is similar to MultiPlayer. However, the game is
              controlled by a Host. The list is hidden from view, so guesses are
              difficult to think of. The host can award a guess in any order,
              and feel free to give hints and control the game however they
              please. Don&apos;t forget, Hosts can also edit and add items to
              the list for completely custom games.
            </Typography>
            <br /> <br />
            <br /> <br />
            <br /> <br />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            onClick={() => setInfoOpen(false)}
            autoFocus
            sx={{
              backgroundColor: '#00B3FF',
              borderRadius: '10px',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={showEmojiPreview}
        onClose={() => handleCloseEmojiPreview()}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {' '}
          {selectedPlayer?.name ? `Set ${selectedPlayer.name}'s Icon` : ''}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseEmojiPreview()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <EmojiPicker
            searchDisabled={true}
            previewConfig={{
              showPreview: false,
            }}
            emojiStyle={EmojiStyle.APPLE}
            onEmojiClick={(e) => {
              changePlayerIcon(selectedPlayer?.id, e.emoji)
              handleCloseEmojiPreview()
            }}
            width="99%"
            height="99%"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            onClick={() => setInfoOpen(false)}
            autoFocus
            sx={{
              backgroundColor: '#00B3FF',
              borderRadius: '10px',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} sx={{ padding: 0.33 }} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const ToggleButton = styled(MuiToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: mainColor,
  },
})

const EditableItem = ({
  value,
  onChange,
}: {
  value: string
  onChange: (value: string) => void
}) => {
  const [localValue, setLocalValue] = useState<string>(value)

  const handleChange = (value: string) => {
    setLocalValue(value)
  }

  return (
    <TextField
      inputProps={{
        style: {
          paddingTop: '2px',
          paddingBottom: '2px',
          paddingLeft: '10px',
        },
      }}
      placeholder="Item"
      value={localValue}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={(e) => onChange(localValue)}
      size="small"
      fullWidth={true}
      variant="standard"
    ></TextField>
  )
}

const floatAndFade = keyframes`
  0% {
    opacity: 1;
    top: 0px;
    scale: 1;
  }
  50% {
    opacity: 1;
    top: -10px;
    scale: 1.25;
    rotate: -5deg;
  }
  100% {
    opacity: 0;
    top: -20px;
    scale: 1.5;
    rotate: -10deg;
  }
`

const pulseAnimate = keyframes`
  0% {
    scale: 1;
  }
  50% {
    scale: 1.05;
  }
  100% {
    scale: 1;
  }
`

const FloatingText = styled('div')(({ theme }) => ({
  position: 'relative',
  animation: `${floatAndFade} 2s linear`,
  textShadow: '2px 4px 3px rgba(0,0,0,0.1)',
  pointerEvents: 'none',
  right: '50%',
  top: '50%',
  fontWeight: 'bold',
}))

const GameCodeText = styled('div')(() => ({
  display: 'inline-block',
  animation: `${pulseAnimate} 3s ease-in-out`,
  animationIterationCount: 'infinite',
}))

const ItemGuessBox = ({
  item,
  list,
  mode,
  onClick,
}: {
  item: Item
  list: Item[]
  mode: string
  onClick: () => void
}) => {
  const [showText, setShowText] = useState(false)

  const maxAvailable = Math.max(
    ...list.filter((item) => !item.team).map((item) => item.points),
  )
  const handleClick = () => {
    if (!item.team) {
      onClick()
      setShowText(true)
      setTimeout(() => {
        setShowText(false)
      }, 2000)
    }
  }

  return (
    <Box
      sx={{
        outline: `2.5px solid ${
          item.guessed ? '#bbb' : getTeamColor(item.team) || '#ddd'
        }`,
        borderRadius: '14px',
        padding: 0,
      }}
      onClick={handleClick}
    >
      <Box
        py={1.5}
        px={1}
        sx={{
          backgroundColor: item.guessed ? '#fafafa' : 'white',
          borderRadius: '12px',
        }}
      >
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <Typography
              component="span"
              sx={{ color: item.guessed ? '#999' : '#111', fontSize: '19px' }}
            >
              {item.team || mode === 'host'
                ? `${item.index}. ${item.title}`
                : item.title}
            </Typography>
            <Typography component="span" variant="caption" color="gray">
              {item.team && item.metric ? ` (${item.metric})` : ''}
            </Typography>
          </Grid>
        </Grid>

        {showText && (
          <div
            style={{
              width: 0,
              height: 0,
              overflow: 'visible',
              position: 'relative',
              top: '-25px',
              right: '-75%',
              color: getTeamColor(item.team) || '#999',
              fontSize: '26px',
            }}
          >
            <FloatingText>
              {mode === 'host' || item.points >= maxAvailable ? '+1' : 'Miss!'}
            </FloatingText>
          </div>
        )}
      </Box>
    </Box>
  )
}

const GuessesCollapse = ({
  show,
  onChange,
  guesses,
  list,
}: {
  list: Item[]
  guesses: number[]
  show: boolean
  onChange: () => void
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          component="div"
          color="gray"
          onClick={onChange}
          sx={{ cursor: 'pointer' }}
        >
          Guess History
          <ExpandMore expand={show}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Typography>
        <Collapse in={show}>
          <Box py={4}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-around" spacing={1}>
                  <Grid item xs={2}>
                    <Typography sx={{ fontSize: '18px' }}>Turn</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ fontSize: '18px' }}>Team</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ fontSize: '18px' }}>Guess</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {guesses.map((index, i) =>
                index > -1 ? (
                  <Grid item xs={12} key={`item-${index}-${i}`}>
                    <Grid container justifyContent="space-around" spacing={1}>
                      <Grid item xs={1}>
                        <Typography
                          component="span"
                          variant="body1"
                          color="gray"
                        >
                          {i + 1}.
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          color={getTeamColor(list[index]?.team)}
                        >
                          <strong>
                            {list[index]?.team === 1 ? 'Team 1' : 'Team 2'}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography component="span">
                          {list[index]?.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} key={`item-${index}-${i}`}>
                    <Grid container justifyContent="space-around">
                      <Grid item xs={1}>
                        <Typography component="span" color="gray" fontSize={18}>
                          {i + 1}.
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          fontSize={18}
                          color={getTeamColor(Math.abs(index))}
                        >
                          <strong>
                            {Math.abs(index) === 1 ? 'Team 1' : 'Team 2'}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography component="span" color="#777" fontSize={18}>
                          Miss
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              )}
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  )
}
